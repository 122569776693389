.cardBody {
  padding: 10px;
  background-color: var(--proposal-card-color);
}

.validatorTable {
  color: var(--proposal-text-color) !important;

  :hover {
    color: var(--proposal-text-color) !important;
  }
}

.proposalCard {
  margin-top: 16px;
}

.platformStats {
  align-items: center;
  justify-content: center;
  display: flex;
  color: var(--font-color);
  font-size: x-large;
  min-height: 40vh;
}

.statsTab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: none !important;
}

.refreshButton {
  margin-bottom: 20px;
}
.card {
  border: var(--proposal-card-border) !important;
}


.tableHead{
  background-color: var(--proposal-card-color) !important;
}

