@import '../../../../Theme/variables.css';
.navTitle {
  font-weight: normal;
  font-size: 0.95rem !important;
  line-height: 20px;
  cursor: pointer;
  /* identical to box height */

  text-align: center;
  // margin-left: 20px;

  color: var(--proposal-nav-color);
}

.navTitle:hover {
  color: var(--proposal-nav-hover-color);
}

.selectedNav {
  color: #27aab9;
  font-weight: bold;
}
