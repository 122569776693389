@import '../../../../Theme/variables.css';
.proposalTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  margin-left: 2px;
  word-wrap: break-word;
  /* identical to box height */

  color: var(--proposal-text-color);
}
