.heading {
  color: #262626;
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
}

.headerContainer {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}

.periodCountContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  
}


.periodCountContainer p {
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  color: #27AAB9;
  margin-bottom: 0px;
}

.periodCountContainer p span {
  font-weight: 700;
  font-size: 1.4rem;
}

.account {
  right: 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  height: 70px;
  padding: 24px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}

.address {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #27AAB9;
  cursor: pointer;
  margin-right: 5px;
}

@media screen and (max-width: 1200px) {
  .heading {
    margin-top: 35px;
  }
}

@media screen and (max-width: 450px) {
  .heading {
    margin-top: 110px;
  }

  .account {
    flex-wrap: wrap;
    align-items: center;
  }

  .account>* {
    flex-basis: 100%;
    margin-top: 5px;
  }

  .address {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .headerContainer {
    margin-top: -30px;
    background: #FFFFFF;
  
   
  }
}

@media screen and (max-width: 767px) {
  .account {
    display: none !important;
  }

  .headerComp1>* {
    margin-top: 5px;
  }

  .headerContainer {
    margin-top: 5px;
  }
}

@media screen and (max-width: 1200px) {
  .headerContainer {
    justify-content: space-between;
  }
}

@media screen and (min-width: 768px) {
  .headerComp1 {
    display: none;
  }
}