.home {
  background: linear-gradient(
      0deg,
      rgba(38, 38, 38, 0.05),
      rgba(38, 38, 38, 0.05)
    ),
    #ffffff;
  flex-grow: 1;
}

.proposalCard {
  display: flex;
  justify-content: center;
}

.proposalCard > * {
  width: 90%;
  max-width: 630px;
}

@media screen and (min-width: 768px) {
  .proposalCard > * {
    width: 90%;
    max-width: 1100px;
  }
  .upperCardContainer {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }
}
