.proposalNavBar {
  padding-bottom: 8px;
  margin-bottom: 5px;

  a {
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 1200px) {
  .navItem {
    margin-top: -12px;
  }
}
