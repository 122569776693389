.proposalCard {
  margin-top: 10px;
}

.cardBody {
  background-color: var(--proposal-card-color);
  color: var(--proposal-text-color);
}
.card {
  border: var(--proposal-card-border) !important;
}
