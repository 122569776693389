@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import '../../Theme/variables.css';

.slick-slide {
  div {
    padding: 10px;
  }
}

.slick-prev {
  margin-left: -50px;

  @media screen and (max-width: 768px) {
    margin-left: -10px;
  }
}

.slick-next {
  margin-right: -30px;

  @media screen and (max-width: 768px) {
    margin-right: 5px;
  }
}

.slick-prev:before,
.slick-next:before {
  color: rgba(26, 170, 186, 1);
  font-size: 15px;
  // line-height: 10px;
  padding: 10px;
  // border-radius: 50%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-weight: bold !important;
  // box-shadow:0px 0px 8px -2px rgba(0, 0, 0, 0.5);
}

.slick-prev:before {
  content: '';
  background: url(../Images/arrowLeft.png);
  background-size: 40px 40px;
  background-repeat: no-repeat;
}

.slick-next:before {
  content: '';
  background: url(../Images/arrowRight.png);
  background-size: 40px 40px;
  background-repeat: no-repeat;
}

@media screen and (min-width: 650px) {
  .modal-mdxl {
    max-width: 630px !important;
  }
}

$theme-colors: (
  'info': #27aab9,
  'information': #17a2b8,
  'completed': #0f626b,
);

.input-group-text {
  border: 0px solid #ced4da !important;
  padding-bottom: 0rem !important;
  padding-top: 0rem !important;
}

@import '~bootstrap/scss/bootstrap';

$sidebar-bg-color: var(--sidebar-color);
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
$sidebar-color: #ffffff;
$highlight-color: #d8d8d8;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

@import '~react-pro-sidebar/dist/scss/styles.scss';

body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: 'Montserrat', sans-serif;
  background: var(--bg-color-1);
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app {
  height: 100%;
  display: flex;
  position: relative;

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .sidebar-btn {
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;

      span {
        margin-left: 5px;
        font-size: 13px;
      }

      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    background: var(--bg-color-1);
    font-family: Montserrat;
    // padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef;

      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;

      a {
        text-decoration: none;
        color: #888;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .social-bagdes {
    margin-top: 10px;

    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;

    > span {
      margin-left: 10px;
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    header {
      h1 {
        transform: translateX(20px);
      }
    }

    .block {
      direction: rtl;

      > span {
        margin-left: 0;
        margin-right: 10px;
      }
    }

    .sidebar-btn-wrapper {
      .sidebar-btn {
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;

    header {
      h1 {
        font-size: 24px;
      }
    }
  }

  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }
  }
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;

  &.red {
    color: #ffffff;
    background: #d63030;
  }

  &.gray {
    color: #ffffff;
    background: #777777;
  }

  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

.pro-sidebar {
  width: 270px;
  min-width: 270px;
}

$col-base-size: 8.33%;

$col-1half: $col-base-size * 1.5;

@media (min-width: 768px) {
  .col-sm-1.5 {
    width: $col-1half;
    flex: 0 0 $col-1half;
    max-width: $col-1half;
  }
}

@media screen and (min-width: 992px) {
  .pro-icon {
    font-size: 20px !important;
  }

  .pro-item-content {
    font-size: 1.1rem;
  }
}

.pro-sidebar-header > div {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;

  span,
  svg {
    font-size: 20px;
  }
}

.pro-icon {
  font-size: 20px !important;
}

.pro-item-content {
  font-size: 1.1rem;
}

* {
  font-family: 'Montserrat', sans-serif;
}

a.sidebarHeader {
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 32px;
  color: #ffffff;
}

a.sidebarHeader:hover {
  text-decoration: none;
  color: white;
}

.pagination {
  flex-wrap: wrap;
}

.intendation {
  padding-left: 15px !important;
}

.ck.ck-editor__editable_inline {
  padding-left: 10px;

  ul,
  ol {
    padding-left: 15px;
  }
}
