.modalTitle {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #262626;
}

.modalBody {
  color: #262626;
  font-size: 15px;
}

.footer {
  justify-content: flex-end !important;
}

.confirmationModalCustom :first-child div div {
  background-color: var(--proposal-card-color);
  color: var(--proposal-text-color);
}
