.title {
  color: #262626;
  font-size: 2rem;
  font-weight: 599;
  text-align: center;
  margin-top: 40px;
}

.confirmationMessage {
  text-align: center;
  margin-top: 15px;
}

.optOutButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 35px;
}

.optOutButton {
  padding: 12px !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
  margin-top: 50px;
}
