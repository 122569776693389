@import '../../../Theme/variables.css';
.progressText {
  font-style: normal;
  font-weight: 600;
  font-size: 0.7rem;
  line-height: 15px;
  color: var(--proposal-text-color-3);
}

@supports (background: -webkit-canvas(squares)) {
  .progressText {
    font-size: 0.59rem;
  }
}
