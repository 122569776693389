/* 1 */
:root {
  --font-color: #333;
  --background-color: #000;
  --primary-color: #1aaaba;
  --primary-text-color: white;
  --green-color :#c2ffd4;

  /* Dashboard */
  --bg-color-1: linear-gradient(
      0deg,
      rgba(38, 38, 38, 0.05),
      rgba(38, 38, 38, 0.05)
    ),
    #ffffff;
  --sidebar-color: #27aab9;
  --topbar-home-color: white;
  --footer-bg-color: rgba(38, 38, 38, 0.1);
  --console-color: #262626;
  --console-bg-color: white;
  --card-title-text-color: #262626;
  --card-highlight-color: rgba(237, 237, 237, 0.4);
  --card-comment-color: #545454;
  --table-background-color: inherit;
  --table-border-color: rgb(221, 221, 221);

  --proposal-card-color: #ffffff;
  --proposal-border-color: rgba(232, 231, 231, 0.7);
  --proposal-text-color: #262626;
  --proposal-text-color-2: rgba(38, 38, 38, 0.6);
  --proposal-text-color-3: rgba(38, 38, 38, 0.85);
  --proposal-header-text-color: #262626;
  --proposal-card-border: 1px solid rgba(0, 0, 0, 0.125);
  --proposal-details-title: #27aab9;

  --vote-card-btn-color: #e2e6ea;
  --vote-card-text-color: #212529;

  --proposal-nav-color: rgba(38, 38, 38, 0.9);
  --proposal-nav-hover-color: rgba(38, 38, 38, 0.9);

  --pagination-background-color: #fff;
  --searchbar-background-color: white;

  --progress-color: #27aab9;
  --pagination-active-color: #007bff;
  --pagination-active-bg-color: white;
  --pagination-inactive-color: black;
  --pagination-hover-color: #e9ecef;


  /* --pagination-active-color: #007bff;
  --pagination-link-color: #007bff;
  --pagination-border-color: #dee2e6; */

  /* Rest */
  --paragraph-color-1: #020808;
  --paragraph-color-2: #586665;
  --heading-clor-1: #07292d;
  --link-color: #007bff;

  --topbar-landing-color: white;
  --landing-text-color: black;
  --landing-gradient-bg: radial-gradient(108.37% 310.61% at 16.53% 26.7%, rgba(33, 33, 79, 0.3) 4.48%, rgba(30, 182, 191, 0.3) 38.5%, rgba(30, 182, 191, 0.3) 70.59%, rgba(33, 33, 79, 0.3) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(180deg, #3F4058 0%, #177FFB 100%);
  --landing-gradient-process: radial-gradient(146.09% 146.09% at 44.41% 118.51%, rgba(42, 33, 79, 0.7) 0%, rgba(23, 127, 251, 0.7) 48.23%, rgba(30, 182, 191, 0.7) 100%) , rgba(33, 33, 79, 0.5); 
  --landing-gradient-description:  radial-gradient(146.09% 146.09% at 44.41% 118.51%, rgba(33, 33, 79, 0.7) 0%, rgba(23, 127, 251, 0.7) 48.23%, rgba(30, 182, 191, 0.7) 100%), rgba(33, 33, 79, 0.5);
  --landing-bg: white;
  --landing-heading-text-color: rgba(7, 41, 45, 1);
  --landing-paragraph-text-color: rgba(88, 102, 101, 1);
  --landing-descitem-text-color: #0D6BC1;


  --landing-features-bg: radial-gradient(146.09% 146.09% at 44.41% 118.51%, rgba(33, 33, 79, 0.7) 0%, rgba(23, 127, 251, 0.7) 48.23%, rgba(30, 182, 191, 0.7) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, rgba(33, 33, 79, 0.5);

  
}

/* 2 */
[data-theme='dark'] {
  --font-color: #eee;
  --background-color: #333;
  --green-color :#024b18;
  --primary-color: #1aaaba;
  --primary-text-color: #d2d2d2;

  /* Dashboard */
  --bg-color-1: black;
  --sidebar-color: #1c1c1e;
  --topbar-home-color: #2b2b2d;
  --footer-bg-color: #393939;
  --console-color: #c2c2c2;
  --console-bg-color: #242425;
  --card-title-text-color: #8c8b92;
  --card-highlight-color: rgba(12, 12, 12, 0.4);
  --card-comment-color: #9e9d9d;
  --table-background-color: #242425 !important;
  --table-border-color: #353535;

  --proposal-card-color: #242425;
  --proposal-border-color: #242425;
  --proposal-text-color: #d2d2d2;
  --proposal-text-color-2: #d2d2d2;
  --proposal-text-color-3: #d2d2d2;
  --proposal-header-text-color: #fff;
  --proposal-card-border: none;
  --proposal-details-title: #2B2B2D;;

  --vote-card-btn-color: #2b2b2d;
  --vote-card-text-color: white;

  --proposal-nav-color: #8c8b92;
  --proposal-nav-hover-color: white;

  --pagination-background-color: #242425;
  --searchbar-background-color: black;

  --progress-color: #2ce977;

  /* --pagination-active-color: white;
  --pagination-link-color: #b3b4b4;
  --pagination-border-color: grey; */

  /* Rest */
  --pagination-active-color: white;
  --pagination-active-bg-color: #242425;
  --pagination-inactive-color: grey;
  --pagination-hover-color: #383838;

  --paragraph-color-1: #020808;
  --paragraph-color-2: #586665;
  --heading-clor-1: #07292d;
  --link-color: #007bff;

  --topbar-landing-color: #1c1c1e;
  --landing-text-color: white;
  --landing-gradient-bg: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), radial-gradient(108.37% 310.61% at 16.53% 26.7%, rgba(33, 33, 79, 0.3) 4.48%, rgba(30, 182, 191, 0.3) 38.5%, rgba(30, 182, 191, 0.3) 70.59%, rgba(33, 33, 79, 0.3) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(180deg, #3F4058 0%, #177FFB 100%);
  --landing-gradient-process: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), radial-gradient(146.09% 146.09% at 44.41% 118.51%, rgba(33, 33, 79, 0.7) 0%, rgba(23, 127, 251, 0.7) 48.23%, rgba(30, 182, 191, 0.7) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, rgba(33, 33, 79, 0.5);
  --landing-gradient-description: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), radial-gradient(146.09% 146.09% at 44.41% 118.51%, rgba(33, 33, 79, 0.7) 0%, rgba(23, 127, 251, 0.7) 48.23%, rgba(30, 182, 191, 0.7) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, rgba(33, 33, 79, 0.5);
  --landing-bg: #242425;
  --landing-heading-text-color:white;
  --landing-paragraph-text-color: white;
  --landing-descitem-text-color: white;

  --landing-features-bg: #2A2C2E;


   /* Overrides the border radius setting in the theme. */
   --ck-border-radius: 4px !important;

   /* Overrides the default font size in the theme. */
   --ck-font-size-base: 14px !important;

   /* Helper variables to avoid duplication in the colors. */
   --ck-custom-background: hsl(270, 1%, 29%) !important;
   --ck-custom-foreground: hsl(255, 3%, 18%) !important;
   --ck-custom-border: hsl(300, 1%, 22%) !important;
   --ck-custom-white: hsl(0, 0%, 100%) !important;

   /* -- Overrides generic colors. ------------------------------------------------------------- */
   --ck-color-base-background:  hsl(0, 0%, 39%) !important;
   --ck-color-base-foreground: var(--ck-custom-background) !important;
   --ck-color-base-border: var(--ck-custom-background) !important;
   --ck-color-focus-border: hsl(208, 90%, 62%) !important;
   --ck-color-text: hsl(0, 0%, 98%) !important;
   --ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2) !important;
   --ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1) !important;

   /* -- Overrides the default .ck-button class colors. ---------------------------------------- */

   --ck-color-button-default-background: var(--ck-custom-background) !important;
   --ck-color-button-default-hover-background: hsl(270, 1%, 22%) !important;
   --ck-color-button-default-active-background: hsl(270, 2%, 20%) !important;
   --ck-color-button-default-active-shadow: hsl(270, 2%, 23%) !important;
   --ck-color-button-default-disabled-background: var(--ck-custom-background) !important;

   --ck-color-button-on-background: var(--ck-custom-foreground) !important;
   --ck-color-button-on-hover-background: hsl(255, 4%, 16%) !important;
   --ck-color-button-on-active-background: hsl(255, 4%, 14%) !important;
   --ck-color-button-on-active-shadow: hsl(240, 3%, 19%) !important;
   --ck-color-button-on-disabled-background: var(--ck-custom-foreground) !important;

   --ck-color-button-action-background: hsl(168, 76%, 42%) !important;
   --ck-color-button-action-hover-background: hsl(168, 76%, 38%) !important;
   --ck-color-button-action-active-background: hsl(168, 76%, 36%) !important;
   --ck-color-button-action-active-shadow: hsl(168, 75%, 34%) !important;
   --ck-color-button-action-disabled-background: hsl(168, 76%, 42%) !important;
   --ck-color-button-action-text: var(--ck-custom-white) !important;

   --ck-color-button-save: hsl(120, 100%, 46%) !important;
   --ck-color-button-cancel: hsl(15, 100%, 56%) !important;

   /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

   --ck-color-dropdown-panel-background: var(--ck-custom-background) !important;
   --ck-color-dropdown-panel-border: var(--ck-custom-foreground) !important;

   /* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */

   --ck-color-split-button-hover-background: var(--ck-color-button-default-hover-background) !important;
   --ck-color-split-button-hover-border: var(--ck-custom-foreground) !important;

   /* -- Overrides the default .ck-input class colors. ----------------------------------------- */

   --ck-color-input-background: var(--ck-custom-background) !important;
   --ck-color-input-border: hsl(257, 3%, 43%) !important;
   --ck-color-input-text: hsl(0, 0%, 98%) !important;
   --ck-color-input-disabled-background: hsl(255, 4%, 21%) !important;
   --ck-color-input-disabled-border: hsl(250, 3%, 38%) !important;
   --ck-color-input-disabled-text: hsl(0, 0%, 78%) !important;

   /* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */

   --ck-color-labeled-field-label-background: var(--ck-custom-background) !important;

   /* -- Overrides the default .ck-list class colors. ------------------------------------------ */

   --ck-color-list-background: var(--ck-custom-background) !important;
   --ck-color-list-button-hover-background: var(--ck-custom-foreground) !important;
   --ck-color-list-button-on-background: hsl(208, 88%, 52%) !important;
   --ck-color-list-button-on-text: var(--ck-custom-white) !important;

   /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */

   --ck-color-panel-background: var(--ck-custom-background) !important;
   --ck-color-panel-border: var(--ck-custom-border) !important;

   /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

   --ck-color-toolbar-background: var(--ck-custom-background) !important;
   --ck-color-toolbar-border: var(--ck-custom-border) !important;

   /* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

   --ck-color-tooltip-background: hsl(252, 7%, 14%) !important;
   --ck-color-tooltip-text: hsl(0, 0%, 93%) !important;

   /* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */

   --ck-color-image-caption-background: hsl(0, 0%, 97%) !important;
   --ck-color-image-caption-text: hsl(0, 0%, 20%) !important;

   /* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */

   --ck-color-widget-blurred-border: hsl(0, 0%, 87%) !important;
   --ck-color-widget-hover-border: hsl(43, 100%, 68%) !important;
   --ck-color-widget-editable-focus-background: var(--ck-custom-white) !important;

   /* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */

   --ck-color-link-default: hsl(190, 100%, 75%) !important;
  

  
}



/* Pagination selectors */
.page-item .page-link {
  position: relative;
  display: block;
  color: var(--pagination-inactive-color);
  text-decoration: none;
  border: none;


}

.page-item .page-link:hover {
  position: relative;
  display: block;
  color: var(--pagination-active-color) !important;
  background-color: var(--pagination-hover-color) !important;
  text-decoration: none;
  border: none;
}

.page-item.active .page-link {
  font-weight: bold;
  background-color: var(--pagination-active-bg-color) !important;
  color: var(--pagination-active-color) !important;
  font-size: 16px;
  margin-top: -2px;
}

figure.table{
  color: var(--proposal-text-color) !important;
}
