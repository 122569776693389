.slanted {
  background-color: #27AAB9;
  height: 450px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 0%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 78%, 0 100%);
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 10px;
}

.slanted {
  position: relative;
}

.slanted::before {
  content: '';

  background-repeat: no-repeat;
  background-position: bottom right;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  /* background-image: url('../../../Assets/Images/Intersect4.png');
    opacity: 0.4;
    background-size: 600px 240px;
    background-position: top right; */

  /* background-image: url('../../../Assets/Images/Intersect2.png');
    background-size: auto 300px;
    opacity: 0.35; */

  /* background-image: url('../../../Assets/Images/Intersect3.png');
    background-size: 1000px 450px;
    opacity: 0.4; */

  /* background-image: url('../../../Assets/Images/Intersect1.png');
    opacity: 0.3;
    background-size: auto 300px;
    background-position: bottom right; */

  /* background-image: url('../../../Assets/Images/Intersect7.png');
    background-size: auto 350px;
    background-position: top right; */

  background-image: url('../../../Assets/Images/Intersect8.png');
  background-size: 1400px 382px;
  background-position: top right;
}

.tabularDataWrapper {
  display: flex;
  /* justify-content: flex-end; */
}

.firstRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 2px !important;
}

.thirdRow {
  margin-top: 30px;
}

.button {
  padding: 0 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.heading {
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 20px;

  color: #ffffff;

  display: flex;
  align-items: center;

  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.secondRow {
  margin-top: 2.5em !important;
}

@media screen and (min-width: 992px) {
  .secondRow {
    align-items: center;
  }

  .button {
    margin-left: 5px;
  }

  .slanted {
    height: 463px;
  }
}

@media screen and (min-width: 1200px) {
  .slanted {
    height: 463px;
  }
}

@media screen and (max-width: 1100px) {
  .slanted {
    height: 280px;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  }
}

@media screen and (max-width: 717px) {
  .slanted {
    height: 300px;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
  }
}

@media screen and (max-width: 640px) {
  .slanted {
    height: 320px;
    clip-path: polygon(0 0, 100% 0, 100% 88%, 0 100%);
  }
}

@media screen and (max-width: 440px) {
  .slanted {
    height: 350px;
    clip-path: polygon(0 0, 100% 0, 100% 94%, 0 100%);
  }
}

@media screen and (max-width: 370px) {
  .slanted {
    height: 300px;
    clip-path: polygon(0 0, 100% 0, 100% 93%, 0 100%);
  }
}

@media screen and (max-width: 350px) {
  .slanted {
    height: 330px;
    clip-path: polygon(0 0, 100% 0, 100% 93%, 0 100%);
  }
}

@media screen and (max-width: 319px) {
  .slanted {
    height: 330px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@media screen and (max-width: 300px) {
  .slanted {
    height: 380px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

.slantedBackground {
  position: absolute;
  top: 97px;
  right: 0;
  width: 500px;
}

@media screen and (max-width: 1125px) {
  .slantedBackground {
    width: 400px;
  }

  .slanted {
    height: auto !important;
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 870px) {
  .slantedBackground {
    width: 340px;
  }
}

@media screen and (max-width: 767px) {
  .slantedBackground {
    display: none;
  }

  .headerComp2 {
    display: none !important;
  }

  .headerComp1>* {
    margin-top: 5px;
  }
}

@media screen and (min-width: 768px) {
  .headerComp1 {
    display: none;
  }
}