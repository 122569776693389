.draftButton button,
.saveButton button {
  font-size: 0.95rem;
}

.cardContainer {
  justify-content: center;
  margin-top: 10px;
}

.proposalCreationPage {
  width: 100%;
}

.card {
  width: 95%;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
}


// .modal-content {
//   background-color: #000;
//   color: #fff;
// }


// .modalBody label,
// .form label {
//   font-style: normal;
//   font-weight: 600;
//   font-size: 0.85rem;
//   line-height: 17px;
//   color: #262626;
// }

// .modalBody input,
// .modalBody select {
//   font-size: 0.86rem;
// }

@media (min-width: 768px) {
  .inputSameLine {
    padding-left: 0px !important;
  }

  .labelSameLine {
    padding-left: 0px !important;
  }
}

.cardContainer .form-group.row {
  display: flex;
  align-items: center;
}

.cardContainer label {
  padding-right: 0px;
}

.modalBody > form > div {
  align-items: center;
}

.saveButton {
  justify-content: flex-end;
  display: flex;
}

.addressContainer {
  padding-left: 2px !important;
}

.address {
  font-size: 1rem;
  word-wrap: break-word;
}

@media screen and (max-width: 575px) {
  .addressContainer {
    padding-left: 15px !important;
  }
}


.modal-content{
  background-color: var(--proposal-card-color) !important;
  color: var(--font-color) !important;   
}

.textColor{
  color: var(--font-color)!important;
  // color: white !important;
}

.inputBox{
  background-color: var(--proposal-card-color) !important;
  color: var(--font-color) !important ;
  border:  1px solid rgb(66, 66, 66) !important;
} 