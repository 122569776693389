.inputGroup {
  max-width: 230px !important;
  font-weight: 300;
  font-size: 10px;
  line-height: 17px;
  text-align: center;
  margin-left: auto;

  input {
    font-size: 0.8rem;
    border-color: var(--proposal-text-color-3) !important;
    color: var(--proposal-text-color-3) !important;
  }

  :first-child {
    background-color: var(--searchbar-background-color);
  }

  :first-child:focus {
    background-color: var(--searchbar-background-color);
  }

  input::placeholder {
    color: var(--proposal-text-color-3) !important;
  }
}

@media screen and (max-width: 1200px) {
  .inputGroup {
    margin-left: 0px;
  }
}

.formControl {
  background-color: var(--proposal-card-color);

  :focus {
    background-color: var(--searchbar-background-color);
  }
}

.inputGroupAppend {
  font-size: 20px;
  display: flex;
  align-items: center;
  // margin-left: 2px !important;
  color: var(--proposal-text-color-3);

  span {
    height: 100%;
  }
}

@media screen and (min-width: 768px) {
  .inputGroup {
    min-width: 220px;
  }
}
