.key {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  color: #ffffff;
}

.value {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  /* identical to box height */

  text-align: right;

  color: #ffffff;
  margin-left: 28px;
}

.tableRow {
  justify-content: space-between;
  min-width: 100%;
  margin-top: 0.8em;
  display: flex;
}

@media screen and (max-width: 500px) {
  .tabular {
    flex-grow: 1;
  }
}
