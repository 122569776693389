.proposalContainer {
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  // margin-top: -15px;
  cursor: pointer;
  // padding: 12px !important;
  // border-bottom: 1px solid rgba(38, 38, 38, 0.1);
  background-color: var(--proposal-card-color);
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 24px !important;
  border-radius: 16px;
}

.proposalInfo {
  font-weight: normal;
  font-size: 9px;
  line-height: 15px;
  color: var(--proposal-text-color);
}

.proposalInfo2 {
  color: var(--proposal-text-color);
}

.budget {
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 15px;
  color: var(--proposal-text-color);
}

.info {
  display: flex;
  flex-wrap: wrap;
}

.badge {
  align-items: center;
  display: flex !important;
  font-size: 0.9rem !important;
}

.infos,
.progressBar {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@media screen and (max-width: 767px) {
  .progressBar {
    margin-top: 5px;
  }
}

@media screen and (min-width: 500px) {
  .proposalInfo {
    font-size: 15px;
  }

  .proposalInfo2 {
    font-size: 15px;
  }

  .proposalContainer {
    margin-bottom: 10px;
  }

  .info {
    margin-top: 5px;
  }
}

@media screen and (min-width: 720px) {
  .proposalInfo {
    font-size: 15px;
  }

  .proposalContainer {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .info {
    margin-top: 5px;
  }

  .proposalInfo2 {
    margin-left: 15px !important;
  }
}

@media screen and (min-width: 500px) {
  .budget {
    margin-left: 15px;
  }
}

.secondRow {
  align-items: center;
  --gap: 12px !important;
  display: inline-flex !important;
  flex-wrap: wrap !important;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap)) !important;
  width: calc(100% + var(--gap)) !important;
  margin-top: -5px !important;
  // margin-bottom: -20px;
}

.secondRow > * {
  margin: var(--gap) 0 0 var(--gap) !important;
  // margin-top: 5px;
}

.firstRow {
  --gap: 12px !important;
  display: inline-flex !important;
  flex-wrap: wrap !important;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap)) !important;
  width: calc(100% + var(--gap)) !important;
  // margin-top: -20px;
}

.firstRow > * {
  margin: var(--gap) 0 0 var(--gap) !important;
}

@media screen and (max-width: 500px) {
  .firstRow {
    margin-top: 0px;
  }
}

.horizontalRule {
  margin-top: -10px !important;
}

@media screen and (max-width: 500px) {
  .horizontalRule {
    margin-top: 10px !important;
  }

  .firstRow {
    margin-top: 0px;
  }

  .secondRow {
    --gap: 5px;
    display: inline-flex !important;
    flex-wrap: wrap !important;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap)) !important;
    width: calc(100% + var(--gap)) !important;
    margin-top: -5px !important;
    // margin-bottom: -20px;
  }

  .secondRow > * {
    margin: var(--gap) 0 0 var(--gap) !important;
    // margin-top: 5px;
  }

  .firstRow {
    --gap: 5px;
    display: inline-flex !important;
    flex-wrap: wrap !important;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap)) !important;
    width: calc(100% + var(--gap)) !important;
    // margin-top: -20px;
  }

  .firstRow > * {
    margin: var(--gap) 0 0 var(--gap) !important;
  }
}

@media screen and (max-width: 767px) {
  .secondRow > *,
  .firstRow > span:last-child {
    width: 100%;
    margin-top: 3px !important;
  }
}

.createProposalButton {
  font-size: 0.95rem !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

@media screen and (min-width: 992px) {
  .createProgressReportButtonContainer {
    display: flex;
    justify-content: flex-end;
  }
}
