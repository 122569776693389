@import '../../Theme/variables.css';
.infoCardContainer {
  // padding-left: 7px !important;
  // padding-right: 7px !important;
  // height: 50px;
}

.myProposalHeading {
  display: flex;
  justify-content: center;
  color: var(--card-title-text-color);
  font-size: 30px;
  font-weight: 550;
  margin-top: 10px;
}

.period {
  text-align: center;
  color: var(--card-title-text-color);
  font-weight: 600;
  font-size: 1.15rem;
}
.textContainer{
  color: var(--font-color);
}

.container {
  background: var(--proposal-card-color) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  // border: solid 1px #27aab9;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    padding-bottom: 12px;
  }
}

.claimButton {
  // border-radius: 20px !important;
  white-space: nowrap;
  text-align: center;
  margin-right: 12px;

  @media screen and (max-width: 1100px) {
    margin-right: 0px;
  }
}
