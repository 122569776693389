.proposalIntro {
  font-size: 1rem;
  color: white;
}

.title {
  font-size: 15px !important;
  font-weight: 700 !important;
}

.value {
  font-size: 36px !important;
  font-weight: 40;
  margin-top: 10px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

.value {
  font-style: normal;
  font-size: 20px;
  line-height: 44px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

@media screen and (max-width: 1100px) {
  .value {
    font-size: 16px !important;
    font-weight: bold;
    margin-top: 0px;
  }

  .title {
    font-weight: normal !important;
  }
}

@media screen and (max-width: 450px) {
  .title {
    font-size: 12px !important;
  }

  .value {
    font-size: 12px !important;
  }
}
